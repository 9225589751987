import React, { Component } from 'react';


import Bills from './../bills/bills'

class Index extends Component {
  render() {
    return (
      <div>
      <Bills />
    
      </div>
    );
  }
}

export default Index;
