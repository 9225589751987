import React from 'react';
import './update.css';
const Update = () => {
  return (
    <div className='container'>
      <div className='updates'>
        <h2>Last updates</h2>
        <div className='info'>
          <p>دلوقتي اي مرسول يدخلنا ان الطلب هيتأخر في المتجر هكلم المتجر اتأكد ان الطلب هيتاخر وهمشي البروسيس بتاعتي الطبيعي... 

بس ناخد بالنا اننا هنقفل كونتاكت ريزون courier withdraw حتي لو الكورير منسحبش و كمل الطلب</p>
<img src="/updatesimage/withdrow.jpg" alt="withdrow" />

        </div>
        <div className='info'>
          <p>Urgent updates 🛑

We do not cancel any order due to prayer time (If we ensure that the store is closed due to prayer time) and if the courier refuses to wait, it will be considered a withdrawal case., we will reassign the order to another courier and follow our process regarding the case scenario.


We should not mention any sentence in the chat that shows the process we follow to compensate the courier like (The distance, Price of compensation to each 1 Km, etc).

يعني مش هقول للكورير دا التعويض المستحق علشان انت مشيت 3 كيلو مثلا .. يعني مش هعرفه اني بحسب مسافة 

Egypt Compensation Process.
We will follow our process on Notion in measuring the distance in all scenarios normally (The courier reaching the pickup location, The courier reaching the drop-off location, etc ) except if we have any technical issue like we don't get coordinates in the tracking history in this case only we will follow the distance email 

يعني دلوقتي مصر هرجع للبروسيس بتاعتي ف الحاجات اللي مش محتاجة مسافة ..

زي مثلا لو هدلفر طلب فهعوض ب total order cost عادي 

لو المرسول راح لبيت العميل ياخد الأوفر كله 
لو مثلا ف نفس المنطقة و وصل للمتجر هعوض بالنص و هكذا ..

لكن لو في حاجة محتاجة مسافة و التراكينج لسه مش شغال هستخدم طريقة الميل ...
و مننساش اي مسافة في اي

 طريقة هتطلعلي احط نوت بيها علشان نسيڤ نفسنا</p>


        </div>
      <div className='info'>EG<p>احنا هنمشي بالميل اللي كان مبعوت اللي فيه ال distance علي الباك ايند ..
      يعني  مش هنمشي بالاوفر أو نص الأوفر و التوتال كوست  وكدا</p></div>
      </div>
      <div className='info'>
          <p>From now we need to add this tag Courier has no cash for any tickets, courier asking for ( Withdraw or to cancel the order).<br />دا في حالة أنه المرسول معهوش فلوس بس</p>
<img src="/updatesimage/2.jpg" alt="withdrow" />

        </div>
    </div>
  );
}

export default Update;
